import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import {
  mapRemarkToPage,
  pageIntroFadeAnimation,
  progressOnScroll,
  setOn,
} from "../helpers";
import {
  Layout,
  Newsletter,
  ProgressNavbar,
  SectionWrapper,
  SocialIcons,
  PreviewCompatibleImage,
  HTMLBody,
} from "../components";
import { Press, PageProps, Artist } from "../models/";
import "../styles/_page.scss";
import "../styles/_press.scss";
import YoutubeVideo from "../components/YoutubeVideo/YoutubeVideo";
import { kebabCase } from "lodash";
import { PasswordProtectedPage } from "../components/Press/PasswordProtectedPage";

export const PressPageTemplate: React.FC<PageProps<Press>> = ({
  isPreviewMode,
  content,
  data,
  data: {
    title,
    artist,
    audioHtml,
    created_at,
    modified_at,
    featuredimage,
    imgYPosition,
    hyperLinks,
    pressReleases,
    youtube_links,
    infoTitle,
  },
}) => {
  const initialNavItems: any = {
    main: {
      id: "main",
      text: title,
      ref: React.useRef<HTMLDivElement>(null),
    },
  };
  const [scrollNavData, setScrollNavData] = React.useState(initialNavItems);

  const scrollEvent = React.useCallback(
    () => progressOnScroll(scrollNavData, setScrollNavData),
    [scrollNavData]
  );

  React.useEffect(() => {
    // pageIntroFadeAnimation();
    setOn("main", scrollNavData, setScrollNavData);
    window.addEventListener("scroll", scrollEvent);

    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  return (
    <div className="page press-page" ref={initialNavItems.main.ref}>
      {isPreviewMode ? null : <ProgressNavbar data={scrollNavData} single />}

      <div className="page__img--dummy" id="home">
        <SectionWrapper
          className="page__header full min-height"
          title={title}
        />

        {typeof featuredimage !== "string" ? (
          <div className="page__image">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: `${title}`,
                imgStyle: {
                  objectPosition: `center ${imgYPosition ?? 50}%`,
                },
              }}
            />
          </div>
        ) : (
          <div
            className="page__image"
            style={{
              background: `url(${featuredimage}) no-repeat`,
              backgroundSize: "cover",
              backgroundPosition: `center ${imgYPosition ?? 50}%`,
            }}
          />
        )}
      </div>

      <div className="page__wrapper press-page" id="main">
        {!artist?.social?.links?.length ? null : (
          <SocialIcons
            data={artist.social}
            size={36}
            style={{ marginTop: "2rem" }}
          />
        )}

        {!content && !infoTitle ? null : (
          <SectionWrapper
            title={infoTitle}
            className="section__small min-height full "
          >
            <HTMLBody content={content} className="page__wrapper__content" />
          </SectionWrapper>
        )}

        <div className="press-grid">
          <div className="press-grid__start">
            {!pressReleases?.length ? null : (
              <SectionWrapper
                title="Press Releases"
                className="section__small min-height full"
              >
                <ul className="page__footer__tags--list flex-column">
                  {pressReleases.map((item) => (
                    <li
                      key={item.releaseSlug + `release-slug`}
                      style={{ width: "100%" }}
                    >
                      <Link
                        to={`/press-release/${kebabCase(item.releaseSlug)}/`}
                        className="link"
                      >
                        <span>{item.text}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </SectionWrapper>
            )}

            {!hyperLinks?.length ? null : (
              <SectionWrapper
                title="Links"
                className="section__small min-height full"
              >
                <ul className="page__footer__tags--list flex-column">
                  {hyperLinks.map((link) => (
                    <li key={link.url + `link`} style={{ width: "100%" }}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                      >
                        <span>{link.text}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </SectionWrapper>
            )}
          </div>

          <div className="press-grid__end">
            {!audioHtml ? null : (
              <SectionWrapper
                title="Audio"
                className="section__small min-height full no-bottom-padding"
              >
                <HTMLBody
                  content={audioHtml}
                  className="page__wrapper__content"
                />
              </SectionWrapper>
            )}

            {!youtube_links?.length ? null : (
              <SectionWrapper
                title={"Video" + (youtube_links.length > 1 ? `s` : "")}
                className="section__small min-height full videos-grid"
              >
                {youtube_links?.map((link, i) => (
                  <YoutubeVideo
                    key={link + i}
                    url={link}
                    title={`${artist?.stageName} Related video`}
                  />
                ))}
              </SectionWrapper>
            )}
          </div>
        </div>

        <footer className="page__footer">
          {isPreviewMode ? null : (
            <div className="press-footer">
              {!created_at ? null : <span>Created: {created_at},</span>}
              {!modified_at ? null : <span>Last updated: {modified_at}</span>}
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};

const PressPage = ({ data, location }) => {
  const { post, artistMarkdown } = data,
    press = mapRemarkToPage<Press>(post),
    artist = !artistMarkdown ? null : mapRemarkToPage<Artist>(artistMarkdown),
    pageData = { ...press, artist };

  return (
    <Layout ignoreNav>
      <Helmet titleTemplate="REAL CVLTVRE | %s">
        <title>{press.title}</title>
        <meta property="og:title" content={`REAL CVLTVRE | ${press.title}`} />
         
        {post.excerpt && <meta name="description" content={post.excerpt} />}
        {post.excerpt && (
          <meta property="og:description" content={post.excerpt} />
        )}
        {press.featuredimage && (<meta
          property="og:image"
          content={press.featuredimage?.childImageSharp?.fluid.src}
        />)}
        
      </Helmet>
      <PasswordProtectedPage password={press.password} location={location}>
        <PressPageTemplate data={pageData} content={post.html} />
      </PasswordProtectedPage>
    </Layout>
  );
};

export default PressPage;

export const pageQuery = graphql`
  query PressPageByID($id: String!, $artistId: String) {
    artistMarkdown: markdownRemark(id: { eq: $artistId }) {
      fields {
        slug
      }
      frontmatter {
        title
        social {
          links {
            type
            url
          }
        }
      }
    }
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 200)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        created_at(formatString: "MMMM DD, YYYY")
        modified_at(formatString: "MMMM DD, YYYY")
        youtube_links
        audioHtml
        infoTitle
        password
        hyperLinks {
          text
          url
        }
        pressReleases {
          text
          releaseSlug
        }
        imgYPosition
        featuredimage {
          childImageSharp {
            fluid(
              maxWidth: 1200
              srcSetBreakpoints: [250, 500, 800, 1200]
              quality: 100
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
